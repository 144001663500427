import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardTitle, CardBody, Row, Table } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import alertify from "alertifyjs";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "../../Slider";

const dataSources = [
  {
    id: 1,
    dataURL: "https://picsum.photos/seed/1/600",
  }

];
const endpoint = "services";

function FormIndexServices() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);

  const [form, setForm] = useState({
    title: "",
    info: "",
    img: "",
  });

  const [validation, setValidation] = useState({
    title: "",
    info: "",
    img: "",
  });
  useEffect(() => {
    if (location.state) {
      if (location.state.product) {
        Object.entries(location.state.product).forEach(([key, value]) => {
          onChangeHandler(key, value);
          setValidation((prevState) => ({ ...prevState, img: "T" }));
        });
      }
      setIsEdit(
        location.state.isEdit !== undefined ? location.state.isEdit : false
      );
    }
  }, []);

  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("title", form.title);
    formData.append("info", form.info);
    formData.append("img", form.img);

    try {
      const rst = await formApi.addPage(formData);
      toast.success(
        "Hizmet eklendi..",
        ((window.location.href = "/services"), 5000),
        2.5
      );
    } catch (e) {
      toast.error("Hizmet eklenemedi..", 2.5);
    }
  };
  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("title", form.title);
      formData.append("info", form.info);
      formData.append("img", form.img);

      try {
        const rst = await formApi.updatePage(formData);
        toast.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/services"), 1000),
          2.5
        );
      } catch (e) {
        toast.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };
  const imageUpload = (data) => {
    setForm((prevState) => ({ ...prevState, ["img"]: data }));
  };

  return (
    <Layout>
      <ToastContainer />
      <main className="home">
        <Container>
          <div className="h1">
            {isEdit ? "Hizmet Güncelleme Paneli" : "Hizmet Ekleme Paneli"}
          </div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <br />
                  <div className="container mt-3 pb-5">
                    <h4>
                      {isEdit ? "Hizmet Güncelleme Paneli" : "Hizmet Ekleme Paneli"}{" "}
                    </h4>
                    <hr />

                    <Form onSubmit={onSubmitHandler}>
                      <Label>
                        <b>Fotoğraflar</b>
                      </Label>

                      <Slider imageUpload={imageUpload} data={dataSources} />

                      <br />
                      <div className="row col-12">
                        <div className="col-12">
                          <FormGroup>
                            <Label for="title">
                              <b>Hizmet Adı</b>
                            </Label>
                            <Input
                              id="title"
                              name="title"
                              placeholder="Lütfen hizmet adını giriniz..."
                              type="text"
                              onChange={(e) =>
                                onChangeHandler(e.target.name, e.target.value)
                              }
                              value={form.title}
                              valid={validation.title === "T"}
                              invalid={validation.title === "F"}
                            />
                          </FormGroup>
                        </div>
                      </div>

                      <Label for="desc">
                        <b>Hizmet İçeriği</b>
                      </Label>
                      <CKEditor
                        name="info"
                        editor={ClassicEditor}
                        onReady={(editor) => {
                          if (form.info !== "") editor.setData(form.info);
                        }}
                        onChange={(event, editor) =>
                          onChangeHandler("info", editor.getData())
                        }
                      />
                      {validation.info === "F" && (
                        <div className="val-message">
                          Bu alan boş bırakılamaz.
                        </div>
                      )}

                      <br />

                      <Button
                        color="dark"
                        type="submit"
                        onClick={(e) => onSubmitHandler(e)}
                        style={{ display: isEdit ? "none" : "inline-block" }}
                      >
                        Ekle
                      </Button>
                      {"  "}
                      <Button
                        type="submit"
                        style={{
                          background: "white",
                          color: "black",
                          display: isEdit ? "inline-block" : "none",
                        }}
                        onClick={(e) => onUpdateHandler(e)}
                      >
                        Güncelle
                      </Button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexServices;
