import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Button, Table, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";

import Layout from "../../Layout";

import Footer from "../../Layout/Footer";
import Commerce from "./Commerce";





const CommerceMain = () => {


  return (
    <Layout>
      <main className="home">
        <Container>
          <div className="h1">E-Ticaret</div>
        </Container>

<Commerce/>
       <Footer/>
      </main>
    </Layout>
  );
};

export default CommerceMain;
