import { lazy, Suspense } from "react";
import { Routes as Switcher, Route, Navigate } from "react-router-dom";
import Loader from "../components/Loader";
import Login from "../pages/Login/Login";

import HomeMain from "../pages/Home/HomeMain";

import FaqMain from "../pages/Faq/FaqMain";
import FormIndexFaq from "../pages/Faq/FormIndexFaq";
import FormIndexServices from "../pages/Services/FormIndexServices";
import ServicesMain from "../pages/Services/ServicesMain";
import FormIndexBlog from "../pages/Blog/FormIndexBlog";
import BlogMain from "../pages/Blog/BlogMain";
import ContactMain from "../pages/Contact/ContactMain";
import FormIndexContact from "../pages/Contact/FormIndexContact";
import FormIndexCommerce from "../pages/Commerce/FormIndexCommerce";
import CommerceMain from "../pages/Commerce/CommerceMain";
import ExporttMain from "../pages/Export/ExportMain";
import FormIndexExportt from "../pages/Export/FormIndexExport";
import FormIndexCard from "../pages/Card/FormIndexCard";
import CarddMain from "../pages/Card/CardMain";
import AboutMain from "../pages/About/AboutMain";
import FormIndexAbout from "../pages/About/FormIndexAbout";
import FormIndexAlibaba from "../pages/Alibaba/FormIndexAlibaba";
import AlibabaMain from "../pages/Alibaba/AlibabaMain";
import FormIndexHomeSlider from "../pages/HomeSlider/FormIndexHomeSlider";
import HomeSliderMain from "../pages/HomeSlider/HomeSliderMain";
// const NotFound = lazy(() => import("../pages/NotFound"));

function Routes() {
  const routeConfig = [
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/home",
      component: HomeMain,
    }
    ,
    {
      path: "faq",
      component: FaqMain,
    },
    {
      path: "form-faq",
      component: FormIndexFaq,
    },
    {
      path: "contact",
      component: ContactMain,
    },
    {
      path: "form-contact",
      component: FormIndexContact,
    },
   
    {
      path: "form-hizmetler",
      component: FormIndexServices,
    },
    {
      path: "hizmetler",
      component: ServicesMain,
    },
    {
      path: "form-blog",
      component: FormIndexBlog,
    },
    {
      path: "blog",
      component: BlogMain,
    },
    {
      path: "form-commerce",
      component: FormIndexCommerce,
    },
    {
      path: "commerce",
      component: CommerceMain,
    },
    {
      path: "form-exportt",
      component: FormIndexExportt,
    },
    {
      path: "exportt",
      component: ExporttMain,
    },
    {
      path: "form-about",
      component: FormIndexAbout,
    },
    {
      path: "about",
      component: AboutMain,
    },
    {
      path: "form-alibaba",
      component: FormIndexAlibaba,
    },
    {
      path: "alibaba",
      component: AlibabaMain,
    },

    {
      path: "form-card",
      component: FormIndexCard,
    },
    {
      path: "card",
      component: CarddMain,
    },
    {
      path: "form-homeslider",
      component: FormIndexHomeSlider,
    },
    {
      path: "homeslider",
      component: HomeSliderMain,
    }
  ];
  return (
    <Switcher>
      {routeConfig.map((route) => (
        <Route
          key={route.path}
          exact
          path={route.path}
          element={
            <Suspense fallback={<Loader />}>
              <route.component />
            </Suspense>
          }
        />
      ))}
    </Switcher>
  );
}

export default Routes;
